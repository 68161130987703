<template>
  <div class="card card-custom gutter-b example example-compact"> 
    <div class="card-header">
      <div class="card-title">
        <h3>Transactions</h3>
      </div>
    </div>

    <div class="card-body">
      <!--Dropdown List-->
      <div class="row">
        <div class="col-sm-4">
          <label class="control-label">Sites</label>
          <select class="custom-select" name="site"  v-model="selectSite" v-on:change="ddlFilter()">
            <option v-for="(item, key) in sites" :key="key" v-bind:value="item.id">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-sm-4">
          <label class="control-label">Banks</label>
          <select class="custom-select" name="selectBank" v-model="selectBank" v-on:change="ddlFilter()">
            <option v-for="(item, key) in banks" :key="key" v-bind:value="item.id">
              {{item.bank_code}}
            </option>
          </select>
        </div>
        <div class="col-sm-4">
          <label class="control-label">Bank Accounts</label>
          <select class="custom-select" name="selectBankAcc" v-model="selectBankAcc" v-if="bankAccs.length">
            <option v-for="(item, key) in bankAccs" :key="key" v-bind:value="item.id" >
              {{item.account_name}} - {{ item.account_number }}
            </option>
          </select>   
          <select class="custom-select" name="selectBankAcc" v-model="selectBankAcc" v-else>
            <option disabled="true">
              No Record Found
            </option>
          </select>    
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-5">
          <label class="control-label">From Date</label>
          <b-form-datepicker id="example-datepicker1" v-model="fromDate" class="mb-2"></b-form-datepicker>
        </div>
        <div class="col-sm-5">
          <label class="control-label">To Date</label>
          <b-form-datepicker id="example-datepicker2" v-model="toDate" class="mb-2"></b-form-datepicker>
        </div>
      </div>
      <div>
        <br>
        <b-button v-on:click="gettransactions(currentPage)">Search</b-button>
        <b-button v-on:click="clear">clear</b-button>
      </div>

      <!--Table Result-->
      <div class="overflow-auto" v-if="transactions.length">
        <b-table
        id="transactionLogTable"
        striped hover
        :items="transactions"
        :fields="headers"
        >
          <template v-slot:cell(url)="data">
            <a :href="`${ data.item.url }`" target="_blank">
              <!-- <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/General/Image.svg" />
          </span> -->
          <b-icon icon="card-image"></b-icon>
          </a>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>

      <div class="overflow-atuo"  v-else>
        <br>
        <p> No Record Found.</p>
      </div>

    <p></p>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "transactions",
  data(){
      return{
          info: '',
          banks: [],
          sites: [],
          bankAccs:[],
          headers: [
            'site', 'bank', 'username', 'description', 'debit','credit','status','remark',
            {
              key: 'date',
              sortable: true
            }
          ],
          transactions: [],
          response:[],
          perPage: '',
          currentPage:1,
          rows:'',
          fromDate:'',
          toDate:'',
          selectSite:'',
          selectBank:'',
          selectBankAcc:''

      };
  },
  //Get sites and banks list data from api for dropdown list
  created(){
   ApiService.get("banks")
   .then(({ data }) => {
      this.banks = data.data.banks;
    })
    .catch(error => {
      console.log("getAllBank() error", error);
    });
      
    ApiService.get("sites")
    .then(({ data }) => {
      this.sites = data.data.sites;
    })
    .catch(error => {
      console.log("getAllSite() error", error);
    });
  },
  mounted() {
    //initial load
    this.gettransactions(this.currentPage)
  },
  //get on change pagination value
  watch: {
        currentPage: function(val){
          this.gettransactions(val)
        }
  },
  methods: {
    ddlFilter() {
      ApiService.query("bankAccs",{
        params: {
          'bankId': this.selectBank,
          'siteId': this.selectSite
        }
      })
      .then(({ data }) => {
        this.bankAccs = data.data.bankAccs;
      })
      .catch(error => {
          console.log("getAllBankAcc() error", error);
      });
    },
    gettransactions(currentPage){
      ApiService.query("transactions",{
        params: {
          'page' : currentPage,
          'bankId': this.selectBank,
          'siteId': this.selectSite,
          'accountId' : this.selectBankAcc,
          'fromDate' : this.fromDate,
          'toDate' : this.toDate,
        }
      })
      .then(({ data }) => {
        this.transactions = data.data.transactions.data;
        this.perPage = data.data.transactions.per_page;
        this.rows = data.data.transactions.total;
      })
      .catch(error => {
        console.log("getAllBankAcc() error", error);
      });
    },
    clear : function(){
      this.selectSite = undefined;
      this.selectBank = undefined;
      this.selectBankAcc = undefined;
      this.fromDate = '';
      this.toDate = '';
      this.gettransactions();
    }
  }
};
</script>

<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
.my-class .dropdown-menu {
    max-height: 100px;
    overflow-y: auto;
  }
.content {
    padding: 20px 0;
}
</style>
